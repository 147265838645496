import './App.css';

function Services() {
  return (
    <div id="services" className="services">
    <h1 className="servicestitle">SERVICES</h1>

    <div className="cards">
      <div className="card">
        <h2 className="cardtitle">Exterior Wash</h2>
        <div className="cardimg"><img src="https://thumbor.forbes.com/thumbor/960x0/https%3A%2F%2Fblogs-images.forbes.com%2Fjulianmitchell%2Ffiles%2F2017%2F03%2FSpiffy-1200x796.jpg" alt="" /></div>
        <div className="time">25 - 40 Minutes</div>
        <ul className="list">
          <li>Wash</li>
          <li>Dry</li>
          <li>Spray Wax</li>
          <li>Wheels</li>
          <li>Windows</li>
        </ul>
        <h3 className="price">$30 / $35</h3>
      </div>

      <div className="card">
        <h2 className="cardtitle">Interior Kleen</h2>
        <div className="cardimg"><img src="https://www.nkyautodetail.com/assets/images/interior2-1400x933.jpg" alt="" /></div>
        <div className="time">30 - 45 Minutes</div>
        <ul className="list">
          <li>Full wipe down/Cleaning</li>
          <li>Vacuum</li>
          <li>Door jambs & crevices</li>
          <li>Polish/Dressing</li>
          <li>Windows</li>
        </ul>
        <h3 className="price">$60 / $65</h3>
      </div>

      <div className="card">
        <h2 className="cardtitle">Full Detail</h2>
        <div className="cardimg"><img src="https://www.nkyautodetail.com/assets/images/interior2-1400x933.jpg" alt="" /></div>
        <div className="time">60 - 90 Minutes</div>
        <ul className="list">
          <li>Best Value!</li>
          <li>Includes all services from Exterior Wash and Interior Kleen</li>
        </ul>
        <h3 className="price">$85 / $95</h3>
      </div>

    </div>

    <h1 className="servicestitle">ADDITIONAL ADD-ONS</h1>

    <div className="cards">
      <div className="card">
        <h2 className="cardtitle">Rug Shampoo</h2>
        <div className="cardimg"><img src="https://www.bauerscarwash.com/uploads/4/9/2/7/492760/carpet-shampoo_orig.jpg" alt="" /></div>
        <ul className="list">
          <p>Have all of your interior rugs deep cleaned by our hot water extractor!</p><br />
        </ul>
        <h3 className="price">$15 / $20</h3>
      </div>

      <div className="card">
        <h2 className="cardtitle">Full Shampoo</h2>
        <div className="cardimg"><img src="https://static.wixstatic.com/media/b72016_e40cea29e9634907b6305a883229fea2~mv2.jpg/v1/crop/x_50,y_0,w_925,h_683/fill/w_632,h_490,al_c,q_80,usm_0.66_1.00_0.01/car-interior-cleaning-1024x683.webp" alt="" /></div>
        <ul className="list">
          <p>Have your full interior deep cleaned by our hot water extractor! This includes your rugs as well.</p>
        </ul>
        <h3 className="price">$40 / $45</h3>
      </div>

      <div className="card">
        <h2 className="cardtitle">Clay Bar</h2>
        <div className="cardimg"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ423jpyg8e1IvcfMLiGuWrpXIXO2TctbYYc1QOqq6KmgZHoDau" alt="" /></div>
        <ul className="list">
          <p>The Clay Bar is used to remove contaminants and pollutants from the surface of your car's paint, glass, fiberglass, and metal. Highly effective in making your car smooth and brighter!</p>
        </ul>
        <h3 className="price">$15 / $20</h3>
      </div>

      <div className="card">
        <h2 className="cardtitle">Pet Hair Removal</h2>
        <div className="cardimg"><img src="https://ecogreentulsa.com/wp-content/uploads/2014/06/beforeafter_pethaironfloorboard.jpg" alt="" /></div>
        <ul className="list">
          <p>You love your pet, not so much the pet hair in your car!</p>
        </ul>
        <h3 className="price">$20 / $25</h3>
      </div>

    </div>

    <div className="services-ftr">
      <h2 className="prices">*Prices shown for: Sedan / Non-Sedan.</h2>

      <div className="ready">
        <h2>Ready to book?</h2>
        <a href="#request"><button className="ready-btn">Request an Appointment</button></a>
      </div>
    </div>
  </div>
  );
}

export default Services;