import './App.css';
import logo from './images/logo.png'

function Header() {
  return (
    <div className="header">
    <div className="logo">
      <img src={logo} alt="logo" />
    </div>
  </div>
  );
}

export default Header;