import './App.css';

function Request() {
  return (
    <div id="request" className="request">
    <h1 className="request-title">Please fill out the form below to request your appointment.</h1>
    <h2 className="request-sub">!Please provide a phone number that we can text for confirmation and appointment updates!</h2>

    <form name="request" method="POST" data-netlify="true" className="form">
      <input type="hidden" name="form-name" value="request" />
      <fieldset className="fieldset">
        <legend>About You</legend>
        <input type="text" id="name" name="name" placeholder="Name" maxLength="16" required></input>
        <input type="tel" id="phone" name="phone" placeholder="888-888-8888" maxlength="12" required></input>
        <input type="text" id="email" name="email" placeholder="Email"></input>
      </fieldset>

      <fieldset className="fieldset">
        <legend>Please indicate the Date and Time you would like to schedule an appointment!</legend>
        <label for="date"></label>
        <input type="date" id="date" name="date" placeholder="date" required></input>
        <input type="time" id="time" name="time" required></input>
      </fieldset>

      <fieldset className="fieldset address-fields">
        <legend>Where would you like us to come?</legend>
        <label for="address"></label>
        <input type="text" id="address" name="address" placeholder="Enter Address" required></input>
        <input type="text" id="city" name="city" placeholder="City" required></input>
        <input type="text" id="state" name="state" placeholder="State" minLength="2" maxlength="2" required></input>
        <input type="tel" id="zip" name="zip" placeholder="Zip" minLength="5" maxLength="5" required></input>
      </fieldset>

      <fieldset className="fieldset">
        <legend>Please select the service you are interested in!</legend>
        <label for="service"></label>
        <select id="service" name="service">
          <option selected>Select One...</option>
          <option value="Sedan-Exterior">Sedan Exterior</option>
          <option value="Sedan-Interior">Sedan Interior</option>
          <option value="Sedan-Full">Sedan Full</option>
          <option value="Suv-Exterior">SUV Exterior</option>
          <option value="SUV-Interior">SUV Interior</option>
          <option value="SUV-Full">SUV Full</option>
        </select>
      </fieldset>

      <fieldset className="fieldset">
        <legend>Please select any add-ons you would also like!</legend><br />
        <div className="checks">
          <label for="track" className="checkspace">Rug Shampoo</label><input type="checkbox" name="shampoo" id="rug-shampoo" value="Rug-Shampoo" /><br />
          <label for="event" className="checkspace">Full Shampoo</label><input type="checkbox" name="shampoo" id="full-shampoo" value="Full-Shampoo" /><br />
          <label for="message" className="checkspace">Clay Bar</label><input type="checkbox" name="clay-bar" id="clay-bar" value="Yes" /><br />
          <label for="message" className="checkspace">Pet Hair Removal</label><input type="checkbox" name="pet-hair" id="pet-hair" value="Yes" />
        </div>
      </fieldset>

      <fieldset className="fieldset">
        <legend>Please tell us the <span className="makemodel">MAKE & MODEL</span> of your vehicle along with anything else you would like us to know!</legend>
        <label for="comments"></label>
        <textarea id="comments" name="comments"></textarea>
      </fieldset>

      <button type="submit" id="submit" name="submit" className="submit-btn">SEND REQUEST</button>
    </form>
  </div>
  );
}

export default Request;