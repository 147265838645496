import './App.css';

function Process() {
  return (
    <div className="howitworks">

    <div className="howcards">

      <div className="howcard">
        <h1 className="number">1</h1>
        <h3 className="howsub">REQUEST YOUR APPOINTMENT</h3>
        <p className="howdetails">Tell us when and where to meet you, along with the services you're interested in.</p>
      </div>

      <div className="howcard">
        <h1 className="number">2</h1>
        <h3 className="howsub">WE COME TO YOU</h3>
        <p className="howdetails">We'll meet at your requested location prepared to provide our services.</p>
      </div>

      <div className="howcard">
        <h1 className="number">3</h1>
        <h3 className="howsub">TELL A FRIEND</h3>
        <p className="howdetails">Word of mouth is still the most effective form of advertising. We really appreciate it!</p>
      </div>

    </div>
    </div>
  );
}

export default Process;