import './App.css';

function Jumbotron() {
  return (
    <div className="jumbotron">

    <div className="buttons">

      <h1 className="jumbotext">WE COME TO YOU!</h1>

      <a href="#services"><button type="button" className="button">
          View Services
        </button></a>

      <a href="#request"><button type="button" className="button">
          Request an Appointment
        </button></a>
    </div>
  </div>
  );
}

export default Jumbotron;