import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './header';
import Jumbotron from './jumbotron'
import Process from './process'
import Services from './services'
import Request from './request' 
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Jumbotron />
    <Process />
    <Services />
    <Request />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
